import styled from "styled-components";

export const BlogPostStyled = styled.article`
  max-width: 660px;
  margin: auto;
  font-family: Inter, sans-serif;
  color: var(--color__dark);

  h2 {
    font-size: 52px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 24px;
  }
`;

export const Text = styled.div`
  border-bottom: 2px solid var(--lightGrey);
  padding-bottom: 50px;
  margin-top: 36px;
  margin-bottom: 36px;
  p {
    margin: 14px auto 20px;
    line-height: 31.5px;
    font-size: 18px;
  }

  h3 {
    margin: 45px 0 30px;
    font-size: 24px;
    font-weight: 800;
    color: var(--color__primary);
  }

  h4 {
    margin: 45px 0 20px;
    font-size: 20px;
    font-weight: 800;
  }
  /* Styles for unordered list */
  ul {
      list-style-type: disc;  /* Bullet points */
      padding-left: 20px;     /* Indent the list */
      margin-bottom: 24px;    /* Space below the list */
  }
  
  ul li {
      font-size: 1rem;         /* Font size for list items */
      line-height: 1.5;        /* Line height for readability */
      margin-bottom: 8px;      /* Space between items */
  }
  
  /* Styles for ordered list */
  ol {
      list-style-type: decimal;  /* Numbered list */
      padding-left: 20px;        /* Indent the list */
      margin-bottom: 24px;       /* Space below the list */
  }
  
  ol li {
      font-size: 1rem;            /* Font size for list items */
      line-height: 1.5;           /* Line height for readability */
      margin-bottom: 8px;         /* Space between items */
  }

`;

export const Date = styled.p`
  font-size: 1.25rem;
  margin: 16px 0px 10px;
  letter-spacing: -0.4px;
  color: var(--midDarkGrey);
  text-align: center;
`;
